import React from "react";
import CharacterImg1 from "../../assets/img/png/character-img-1.png";
import CharacterImg2 from "../../assets/img/png/character-img-2.png";
import CharacterImg3 from "../../assets/img/png/character-img-3.png";
import CharacterImg4 from "../../assets/img/png/character-img-4.png";
import CharacterImg5 from "../../assets/img/png/character-img-5.png";
import FlipImg from "../../assets/img/png/flip-ape-img.png";
import FlipLadyImg from "../../assets/img/png/fame-lady-img.png";
import FlipImg2 from "../../assets/img/png/superyeti-back-img.png";
import FlipImg3 from "../../assets/img/png/DJenerates-back-img.png";
import FlipImg4 from "../../assets/img/png/BAYC-back-img.png";

const Characters = () => {
  return (
    <>
      <section className="bg-color-black characters" id="tickets">
        <div className="container py-5">
          <div className="row">
            <div
              className="col-12 col-xl-9 mx-auto"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <h1 className="heading text-center mb-4 mb-sm-5">
                “On their Adventures,
                <span className="color-pink">the Metashooters</span> will Meet
                <span className="color-pink"> Characters</span> from Projects
                like this”
              </h1>
            </div>
          </div>
          <div className="row justify-content-center my-sm-3 py-sm-3">
            <div
              className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 my-4"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="flip-container cursor-pointer"
                onTouchStart="this.classList.toggle('hover');"
              >
                <div className="flipper">
                  {/* <!-- front content --> */}
                  <div className="front d-flex align-items-center justify-content-center p-3">
                    <img className="w-100" src={CharacterImg1} alt="Img" />
                  </div>
                  {/* <!-- back content --> */}
                  <div className="back d-flex align-items-center justify-content-center">
                    <div>
                      <p className="para fw-semibold mb-1">SuperYeti#6683</p>
                      <img className="my-2" src={FlipImg2} alt="Img" />
                      <p className="para fw-semibold px-1 mb-0">
                        The Super Yetis are the 'evil underlinigs' that work in
                        the MetaVerseBar and hunt the escaped Metashooters.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 my-4"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="flip-container cursor-pointer"
                onTouchStart="this.classList.toggle('hover');"
              >
                <div className="flipper">
                  {/* <!-- front content --> */}
                  <div className="front d-flex align-items-center justify-content-center p-3">
                    <img className="w-100" src={CharacterImg2} alt="Img" />
                  </div>
                  {/* <!-- back content --> */}
                  <div className="back d-flex align-items-center justify-content-center">
                    <div>
                      <p className="para fw-semibold mb-1">DJENERATES #8911</p>
                      <img className="my-2" src={FlipImg3} alt="Img" />
                      <p className="para fw-semibold px-2 px-sm-4 px-xl-3 mb-0">
                        DJenerates party crowd and DJs in the MetaVerseBar
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 my-4"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="flip-container cursor-pointer"
                onTouchStart="this.classList.toggle('hover');"
              >
                <div className="flipper">
                  {/* <!-- front content --> */}
                  <div className="front d-flex align-items-center justify-content-center p-3">
                    <img className="w-100" src={CharacterImg3} alt="Img" />
                  </div>
                  {/* <!-- back content --> */}
                  <div className="back d-flex align-items-center justify-content-center">
                    <div>
                      <p className="para fw-semibold mb-1">BAYC#9658</p>
                      <img className="my-2" src={FlipImg4} alt="Img" />
                      <p className="para fw-semibold px-2 px-sm-4 px-xl-3 mb-0">
                        Aped in on MetaShooters?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 my-4 ms-sm-2 ms-xl-0"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="flip-container cursor-pointer"
                onTouchStart="this.classList.toggle('hover');"
              >
                <div className="flipper">
                  {/* <!-- front content --> */}
                  <div className="front d-flex align-items-center justify-content-center p-3">
                    <img className="w-100" src={CharacterImg4} alt="Img" />
                  </div>
                  {/* <!-- back content --> */}
                  <div className="back d-flex align-items-center justify-content-center">
                    <div>
                      <p className="para fw-semibold mb-1">FAME LADY #3456</p>
                      <img
                        className="my-2 lady-img"
                        src={FlipLadyImg}
                        alt="Img"
                      />
                      <p className="para fw-semibold px-2 px-sm-4 px-xl-3 mb-0">
                        Lady Myra has a wild past. Now she runs the
                        MetaVerseBar.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 my-4"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="flip-container cursor-pointer"
                onTouchStart="this.classList.toggle('hover');"
              >
                <div className="flipper">
                  {/* <!-- front content --> */}
                  <div className="front d-flex align-items-center justify-content-center p-3">
                    <img className="w-100" src={CharacterImg5} alt="Img" />
                  </div>
                  {/* <!-- back content --> */}
                  <div className="back d-flex align-items-center justify-content-center">
                    <div>
                      <p className="para fw-semibold mb-1">MAYC #3456</p>
                      <img className="my-2" src={FlipImg} alt="Img" />
                      <p className="para fw-semibold px-3 mb-0">
                        Not a Serum - just a Shooter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Characters;
