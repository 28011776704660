import React from "react";
import IpHubImg from ".././assets/img/png/Ip-img.png";

const IpHub = () => {
  return (
    <>
      <section className="ip-section">
        <div className="container py-5">
          <div className="row align-items-center">
            <div
              className="col-12 col-lg-7 col-xl-6"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <img className="w-100" src={IpHubImg} alt="Img" />
            </div>
            <div
              className="col-12 col-lg-5 col-xl-6 my-4 my-lg-0"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="pe-xl-5 text-center text-sm-end">
                <h1 className="heading mb-4 mb-sm-3">
                  <span className="color-pink">IP </span>HUB
                </h1>
                <p className="para ps-xxl-5 mb-0">
                  The MetaShooters act as an “IP HUB” – Intellectual property
                  hub. When the MetaShooters meet other NFT projects, we will
                  collaborate our talents and values and mix IP with buying
                  their NFT.
                </p>
                <p className="para ps-xxl-5 my-2 py-1">
                  These IP NFTs are owned by the MetaShooters company and will
                  be used for generating more value for the community. This can
                  be in the form of ownership marketing and collaboration
                  projects. But also in the form of sublicensing it to
                  MetaShooters scholarship projects.
                </p>
                <p className="para ps-xxl-5 mb-0">
                  Our goal is to acquire as many assets for the benefit of the
                  MetaShooters brand and the underlying community behind it
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IpHub;
