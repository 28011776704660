import { useEffect, useState } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./views/Home";
import Sale from "./views/Sale";
import Header from "./components/common/Header";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/common/Footer";
import BackToTop from "./components/common/BackToTop";
import PreLoader from "./components/common/PreLoader";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const [isLoading, setLoading] = useState(true);
  AOS.init({ once: true });

  // ON COMPONENT UNMOUNT AFTER 1 SECOND SET LOADING TO TRUE
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      {/* IS LOADING === TRUE THEN SHOW PRELOADER ON FULL SCREEN  */}
      {isLoading ? (
        <PreLoader />
      ) : (
        <Router>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/sale" component={Sale} />
          </Switch>
          <Footer />
          <BackToTop />
        </Router>
      )}
    </>
  );
}

export default App;
