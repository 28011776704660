import React from "react";
import ScholarshipImg from ".././assets/img/png/Scholarship-img.png";

const IpScholarship = () => {
  return (
    <>
      <section className="ip-scholarship pb-sm-5">
        <div className="container mb-xl-5 pb-5 py-sm-5">
          <div className="row align-items-center justify-content-center justify-content-lg-between ">
            <div
              className="col-12 col-lg-7 col-xl-6 my-4 my-lg-0 ms-xl-5 order-2 order-lg-1 z-index-1"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div className="px-xxl-5">
                <h1 className="heading mb-4 mb-sm-3">
                  <span className="color-pink">MetaShooters </span>
                  IP Scholarship
                </h1>
                <p className="para mb-0">
                  Do you have a skill or outstanding idea, but can’t get quite
                  to show what you are made off? The MSIPS gives you the
                  resources you need.
                </p>
                <h5 className="font-md color-pink fw-medium my-2 pt-1">
                  Access to
                </h5>
                <ul>
                  <li className="para">The IP HUB</li>
                  <li className="para">The community</li>
                  <li className="para">The MetaShooters</li>
                  <li className="para">The MetaVerse (Sandbox Land)</li>
                  <li className="para">
                    Developers, designers , artists, and/or marketers.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-7 col-sm-6 col-lg-5 col-xl-4 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <img className="w-100" src={ScholarshipImg} alt="Img" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IpScholarship;
