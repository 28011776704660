import React from "react";
import marya from "../assets/img/png/marya.png";
import ThreeDSlider from "./common/ThreeDSlider";

const Marya = () => {
  return (
    <>
      <section className="Marya my-5 py-5" id="tickets">
        <div className="container">
          <div className="row justify-content-center align-items-center justify-content-xl-between">
            <div
              className="col-12 col-xl-6  z-index-1"
              data-aos="fade-right"
              data-aos-duration="1600"
            >
              <div className="d-flex align-items-center">
                <div className=" pe-3">
                  <img className="w-100 " src={marya} alt="marya" />
                </div>
                <div className="ps-2 ps-sm-4">
                  <h4 className="marya-heading text-white">Myra</h4>
                  <h4 className="font-lg text-white">Owner MetaVerseBar</h4>
                </div>
              </div>
              <div className="position-relative border-custom mt-5">
                <div className="borderr">
                  <p className="small-heading text-center py-sm-3 mb-0">
                    "The best shooters are served in the MetaVerseBar. <br />
                    Don't miss that opportunity to become a substantial part of
                    this project"
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-10 col-sm-8 col-xl-6 my-auto py-5 py-xl-0   bar-slider z-index-1"
              data-aos="fade-left"
              data-aos-duration="1600"
            >
              <ThreeDSlider />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Marya;
