export const headerData = [
  {
    title: "INFORMATION",
    url: "#information",
  },
  {
    title: "TICKETS",
    url: "#tickets",
  },
  {
    title: "LINE UP",
    url: "#line-up",
  },
  {
    title: "FOUNDER TOKEN",
    url: "#founder-token",
  },
  {
    title: "SALE",
    url: "",
  },
];
