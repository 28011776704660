import React from "react";
import ThreeDSlider from "../common/ThreeDSlider";

export default function Metaversebar() {
  return (
    <>
      <section className="metaversebar py-xl-5 py-xxl-0">
        <div className="container pb-5 py-sm-5">
          <div className="py-5 row flex-column-reverse flex-xl-row align-items-center justify-content-center justify-content-lg-between mb-0 mb-sm-5 mb-xxl-0">
            <div
              className="col-12 col-lg-10 col-xl-6  my-4 my-xl-0"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div className="px-xxl-5 text-center text-sm-start">
                <h1 className="heading mb-4 mb-sm-3">
                  <span className="color-pink">Escape </span>
                  from MetaVerseBar
                </h1>
                <p className="para mb-0">
                  The MetaShooters live in the MetaVerseBar and party every
                  moment of their lives. One day, they discover there must be
                  more to life than just having fun, partying and repeat – so
                  they decide to escape.
                </p>
                <p className="para my-2 py-1">
                  Of course the owner of the bar, Mistress Myra
                  <span className="color-pink"> (Fame Lady Squad)</span> is
                  furious so she sends her underlings{" "}
                  <span className="color-pink">(Super Yetis)</span> to catch
                  them and bring them back to the bar.
                </p>
                <p className="para mb-0">
                  On their journey they meet a variety of NFT Characters, make
                  new friends and learn serious as well as hilarious lessons
                  about life!
                </p>
                <div className="mt-4 mt-lg-5">
                  <a
                    className="common-btn border-0 text-decoration-none"
                    href="https://opensea.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get Your MintGlass on opensea.io
                  </a>
                </div>
              </div>
            </div>

            {/* SLIDER */}
            <div className="col-11 col-sm-12 col-xl-6  bar-slider mb-sm-4">
              <ThreeDSlider />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
