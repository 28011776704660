import React from "react";
import Slider from "react-slick";
import BarSliderImg1 from "../../assets/img/png/bar-slider-img-1.png";
import BarSliderImg2 from "../../assets/img/png/bar-slider-img-2.png";
import BarSliderImg3 from "../../assets/img/png/bar-slider-img-3.png";
import BarSliderImg4 from "../../assets/img/png/bar-slider-img-4.png";
import BarSliderImg5 from "../../assets/img/png/bar-slider-img-5.png";
import BarSliderImg6 from "../../assets/img/png/bar-slider-img-6.png";
import BarSliderImg7 from "../../assets/img/png/bar-slider-img-7.png";
import BarSliderImg8 from "../../assets/img/png/bar-slider-img-8.png";
import BarSliderImg9 from "../../assets/img/png/bar-slider-img-9.png";
import BarSliderImg10 from "../../assets/img/png/bar-slider-img-10.png";

const ThreeDSlider = () => {
  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    centerPadding: "0px",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };
  return (
    <>
      <Slider className="px-md-4 px-lg-5 px-xl-0 pb-2 pb-sm-0" {...settings}>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg1} alt="Img" />
        </div>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg2} alt="Img" />
        </div>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg3} alt="Img" />
        </div>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg4} alt="Img" />
        </div>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg5} alt="Img" />
        </div>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg6} alt="Img" />
        </div>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg7} alt="Img" />
        </div>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg8} alt="Img" />
        </div>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg9} alt="Img" />
        </div>
        <div>
          <img className="w-100 px-5 px-sm-0" src={BarSliderImg10} alt="Img" />
        </div>
      </Slider>
    </>
  );
};

export default ThreeDSlider;
