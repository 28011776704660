import React from "react";
import Marya from "../components/Marya";
import Roadmap from "../components/sale/Roadmap";
import SaleHeroSection from "../components/sale/SaleHeroSection";

const Sale = () => {
  return (
    <>
      <SaleHeroSection />
      <Roadmap />
      <Marya />
    </>
  );
};

export default Sale;
