import React from "react";
import Logo from "../../assets/img/png/metashooterslogo.png";

const PreLoader = () => {
  return (
    <>
      <div className="pre-loader-style">
        <img className="load-animation" src={Logo} alt="Img" />
      </div>
    </>
  );
};

export default PreLoader;
