import React from "react";
import MeetMetaShooter from "../MeetMetaShooter";
import IpHub from "../IpHub";
import IpScholarship from "../IpScholarship";

const IPsection = () => {
  return (
    <>
      <section className="the-ip-section">
        <MeetMetaShooter />
        <IpHub />
        <IpScholarship />
      </section>
    </>
  );
};

export default IPsection;
