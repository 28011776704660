import React from "react";

const Roadmap = () => {
  return (
    <>
      <section className="roadmap-section bg-color-black py-5" id="roadmap">
        <div className="container py-sm-5">
          <div className="row">
            <div
              className="col-12"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <h1 className="heading color-pink text-center mb-md-5 pb-4">
                Roadmap
              </h1>
            </div>
          </div>
          {/* row-1 */}
          <div className="row">
            <div className="col-3 col-sm-2 line">
              <div className="roadmap-circle-phase heading color-pink text-center mx-auto">
                <span className="pt-sm-1 pt-lg-2">1</span>
              </div>
            </div>
            <div
              className="col-9 col-sm-10 col-xl-9 mb-4 pb-3 ps-0 ps-sm-2"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="roadmap-content">
                <h1 className="sub-heading fw-bold mb-2 text-capitalize">
                  MetaShooters Alpha Show
                </h1>
                <p className="para text-capitalize mb-1">
                  news and information around party and music related nft
                  projects
                </p>
              </div>
            </div>
          </div>
          {/* row-2 */}
          <div className="row">
            <div className="col-3 col-sm-2 line">
              <div className="roadmap-circle-phase heading color-pink text-center mx-auto">
                <span className="pt-sm-1 pt-lg-2">2</span>
              </div>
            </div>
            <div
              className="col-9 col-sm-10 col-xl-9 mb-4 pb-3 ps-0 ps-sm-2"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="roadmap-content">
                <h1 className="sub-heading fw-bold mb-2 text-capitalize">
                  MetaShooters - Escape from the MetaVerseBar story
                </h1>
                <p className="para mb-2 text-capitalize">
                  chance of featured NFT IP you own in a storyline. (For example
                  Fame Lady Squad, Super Yeti, DeadHead, World of Woman, or
                  others, that let you use the IP of your NFT)
                </p>
                <p className="para mb-2 text-capitalize">IRL Brand collabs</p>
                <p className="para mb-2 text-capitalize">
                  Think liquer company collabs
                </p>
                <p className="para mb-2 text-capitalize">
                  Exclusive MetaShooters Festivals and Events
                </p>
                <p className="para mb-2 text-capitalize">Shooters Bingo</p>
                <p className="para mb-1 text-capitalize">
                  Lady Myras bounty hunt
                </p>
              </div>
            </div>
          </div>
          {/* row-3 */}
          <div className="row">
            <div className="col-3 col-sm-2 line">
              <div className="roadmap-circle-phase heading color-pink text-center mx-auto">
                <span className="pt-sm-1 pt-lg-2">3</span>
              </div>
            </div>
            <div
              className="col-9 col-sm-10 col-xl-9 mb-4 pb-3 ps-0 ps-sm-2"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="roadmap-content">
                <h1 className="sub-heading fw-bold mb-2 text-capitalize">
                  IRL Brand collabs
                </h1>
                <p className="para text-capitalize mb-1">
                  As founder, you'll be rewarded a very special VIP MetaShooter
                  NFT.
                </p>
              </div>
            </div>
          </div>
          {/* row-4 */}
          <div className="row">
            <div className="col-3 col-sm-2 line">
              <div className="roadmap-circle-phase heading color-pink text-center mx-auto">
                <span className="pt-sm-1 pt-lg-2">4</span>
              </div>
            </div>
            <div
              className="col-9 col-sm-10 col-xl-9 mb-4 pb-3 ps-0 ps-sm-2"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="roadmap-content">
                <h1 className="sub-heading fw-bold mb-2 text-capitalize">
                  MetaShooters Schoolarship
                </h1>
                <p className="para text-capitalize mb-1">
                  apply for MetaShooters Ressources and IP to build your own
                  project or company.
                </p>
              </div>
            </div>
          </div>
          {/* row-5 */}
          <div className="row">
            <div className="col-3 col-sm-2 line">
              <div className="roadmap-circle-phase heading color-pink text-center mx-auto">
                <span className="pt-sm-1 pt-lg-2">5</span>
              </div>
            </div>
            <div
              className="col-9 col-sm-10 col-xl-9 mb-4 pb-3 ps-0 ps-sm-2"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="roadmap-content">
                <h1 className="sub-heading fw-bold mb-2 text-capitalize">
                  MetaShooters wallet
                </h1>
                <p className="para text-capitalize mb-1">
                  Benefits from other projects owned by the MetaShooters wallet
                  will be given to the community.
                </p>
              </div>
            </div>
          </div>
          {/* row-6 */}
          <div className="row">
            <div className="col-3 col-sm-2 line">
              <div className="roadmap-circle-phase heading color-pink text-center mx-auto">
                <span className="pt-sm-1 pt-lg-2">6</span>
              </div>
            </div>
            <div
              className="col-9 col-sm-10 col-xl-9 mb-4 pb-3 ps-0 ps-sm-2"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="roadmap-content">
                <h1 className="sub-heading fw-bold mb-2 text-capitalize">
                  MetaShooters Sandbox.game
                </h1>
                <p className="para text-capitalize mb-2">
                  Every founder receives 10 FREE mints before the general
                  admission starts. (Gas fees only)
                </p>
                <ul>
                  <li className="para text-capitalize mb-2">
                    Escape from the MetaVerseBar experience
                  </li>
                  <li className="para text-capitalize mb-2">
                    VIP Lounges at the MetaVerseBar
                  </li>
                  <li className="para text-capitalize mb-3 pb-3">
                    Exclusive sandbox MetaShooters character
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* row-7 */}
          <div className="row">
            <div className="col-3 col-sm-2 position-relative">
              <div className="roadmap-circle-phase heading color-pink text-center mx-auto">
                <span className="pt-sm-1 pt-lg-2">7</span>
              </div>
            </div>
            <div
              className="col-9 col-sm-10 col-xl-9 ps-0 ps-sm-2"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="roadmap-content">
                <h1 className="sub-heading fw-bold mb-2 text-capitalize">
                  Friends of MetaShooters
                </h1>
                <p className="para text-capitalize mb-1">
                  WL and and other benefits from collab projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Roadmap;
