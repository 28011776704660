import React from "react";
import TheMetaversebarImg1 from "../../assets/img/png/the-metaverse-img.png";
import TheMetaversebarImg2 from "../../assets/img/png/the-metaverse-img-2.png";

const TheMetaverse = () => {
  return (
    <>
      <section className="the-metaverse-bar">
        <div className="container">
          <div className="row align-items-end justify-content-center justify-content-lg-between the-metaverse-bar-content">
            <div className="col-9 col-sm-7 col-lg-5 z-index-1">
              <div className="row">
                <div
                  className="col-6 z-index-1"
                  data-aos="fade-up-right"
                  data-aos-duration="3000"
                >
                  <img className="w-100" src={TheMetaversebarImg1} alt="Img" />
                </div>
                <div
                  className="col-6 z-index-1"
                  data-aos="fade-up-right"
                  data-aos-duration="3000"
                >
                  <img className="w-100" src={TheMetaversebarImg2} alt="Img" />
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 col-xl-6 my-4 my-lg-0 ms-xl-5 z-index-1"
              data-aos="fade-up-left"
              data-aos-duration="3000"
            >
              <div className="text-center text-sm-end px-lg-4 px-xl-0">
                <h1 className="heading mb-4 mb-sm-3">
                  The
                  <span className="color-pink"> MetaVerseBar</span>
                </h1>
                <p className="para mb-2">
                  The MetaVerseBar is a place, that exists in different
                  dimensions. In Sandbox, the MetaVerseBar is going to have 3
                  primary functions (so far).
                </p>
                <p className="para mb-2">1.) Event Venue</p>
                <p className="para mb-2">
                  2.) Sandbox Game ‘Escape from the MetaVerseBar’.
                </p>
                <p className="para mb-4">3.) Hosting the VIP Lounges.</p>
                <div className="pt-3 d-flex align-items-center justify-content-end">
                  <button className="common-btn my-btn border-0 me-1">
                    VIP Lounges
                  </button>
                  <button className="common-btn border-0 ms-3">
                    Sandbox.Game
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TheMetaverse;
