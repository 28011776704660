import React from "react";
import Characters from "../components/home/Characters";
import Guest from "../components/home/Guest";
import Hero from "../components/home/Hero";
import IPsection from "../components/home/IPsection";
import Metaversebar from "../components/home/Metaversebar";
import Roadmap from "../components/home/Roadmap";
import Team from "../components/home/Team";
import TheMetaverse from "../components/home/TheMetaverse";
import Token from "../components/home/Token";

const Home = () => {
  return (
    <>
      <Hero />
      <Guest />
      <Metaversebar />
      <Characters />
      <IPsection />
      <TheMetaverse />
      <Team />
      <Roadmap />
      <Token />
    </>
  );
};

export default Home;
