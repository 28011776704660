import React from "react";
import ReactPlayer from "react-player";

const Guest = () => {
  return (
    <>
      <section className="guest py-5" id="information">
        <div className="container mt-sm-5 py-sm-5">
          <div className="row my-lg-5 py-sm-5 align-items-center">
            <div
              className="col-12 col-lg-7 col-xl-6"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div className="video-element-wrapper">
                <ReactPlayer
                  className="react-player"
                  url="https://www.youtube.com/watch?v=U7wKoQrrAlk&t=10s"
                  controls={true}
                />
              </div>
            </div>
            <div
              className="col-12 col-lg-5 col-xl-6 my-4 my-lg-0 z-index-1"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="px-xl-5 text-center text-sm-start">
                <h1 className="heading mb-4 mb-sm-3">
                  Join the <span className="color-pink">GuestList</span>
                </h1>
                <p className="para pb-4 mb-0 mb-sm-3 text-start">
                  <b>Sign up now for the Guestlist Newsletter - now.</b> <br />{" "}
                  The MetaVerseBar guestlist newsletter will give you <br /> -
                  first hand MetaShooters information <br /> - premint and
                  whitelist (the guestlist) opportunities <br /> - raffles and
                  giveaways from MetaShooters and partnering projects <br /> +
                  Plus, you get updated with every episode of the MetaShooters
                  alpha show!
                </p>
                <div className="row align-items-center py-2 py-lg-1 mx-2 mx-sm-0">


                  <form
                    action="https://app.getresponse.com/add_subscriber.html"
                    acceptCharset="utf-8"
                    method="post"
                    className="email-join-btn  d-flex align-items-center py-2 py-lg-1 mx-2 mx-sm-0 pe-lg-1"
                  >
                    <input
                      className="para my-email bg-transparent border-0 w-100 ms-2"
                      type="text"
                      name="email"
                      placeholder="Email address"
                    />
                    <br />

                    <input
                      className="para my-email bg-transparent border-0 w-100 ms-2"
                      type="hidden"
                      name="campaign_token"
                      value="5jORK"
                    />

                    <input
                      className="para my-email bg-transparent border-0 w-100 ms-2"
                      type="hidden"
                      name="start_day"
                      value="0"
                    />

                    <input
                      className="common-btn border-0 px-5"
                      type="submit"
                      value="Join"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Guest;
