import React from "react";
import { useHistory } from "react-router-dom";

const Token = () => {
  const history = useHistory();

  return (
    <>
      <section className="bg-color-black" id="founder-token">
        <div className="container py-5">
          <div className="row py-lg-5 align-items-center justify-content-center">
            <div
              className="col-12 col-sm-10"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="text-center">
                <h1 className="heading">
                  <span className="color-pink">Early Bird </span>
                  Special
                </h1>
                <p className="para mb-0 fw-medium">
                  MetaShooters offers the unique opportunity to make a VIP table
                  reservation, before we launch. Aka - we are looking for
                  supporters, that are interested to ape in and earn exclusive
                  rewards.
                </p>
                <div className="mt-4">
                  <a
                    onClick={() => history.push("/sale")}
                    className="common-btn border-0 text-decoration-none"
                    href="#"
                    rel="noreferrer"
                  >
                    Find out more here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Token;
