import React from "react";
import Slider from "react-slick";
import TeamImg from "../../assets/img/png/team-img-1.png";
import TeamImg2 from "../../assets/img/png/team-img-2.png";
import TeamImg3 from "../../assets/img/png/team-img-3.png";
import TeamImg4 from "../../assets/img/png/team-img-4.png";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="bg-color-black py-5" id="line-up">
        <div className="container mt-lg-5">
          <div className="row">
            <div className="col-12" data-aos="fade-up" data-aos-duration="3000">
              <h1 className="heading color-pink text-center my-md-5 pb-4">
                Team
              </h1>
            </div>
          </div>
          <div className="col-12" data-aos="fade-up" data-aos-duration="3000">
            <Slider
              className="px-md-4 px-lg-5 px-xl-3 pb-2 pb-sm-0"
              {...settings}
            >
              <div>
                <div className="team-slider text-center px-2">
                  <div className="team-img p-3">
                    <img className="w-100" src={TeamImg} alt="Img" />
                  </div>
                  <h3 className="sub-heading mt-3 pt-1">Benno</h3>
                  <h4 className="small-heading">Creator</h4>
                </div>
              </div>
              <div>
                <div className="team-slider text-center px-2">
                  <div className="team-img p-3">
                    <img className="w-100" src={TeamImg2} alt="Img" />
                  </div>
                  <h3 className="sub-heading mt-3 pt-1">Jon</h3>
                  <h4 className="small-heading">Iaculis vel</h4>
                </div>
              </div>
              <div>
                <div className="team-slider text-center px-2">
                  <div className="team-img p-3">
                    <img className="w-100" src={TeamImg3} alt="Img" />
                  </div>
                  <h3 className="sub-heading mt-3 pt-1">Donec</h3>
                  <h4 className="small-heading">Quam et</h4>
                </div>
              </div>
              <div>
                <div className="team-slider text-center px-2">
                  <div className="team-img p-3">
                    <img className="w-100" src={TeamImg4} alt="Img" />
                  </div>
                  <h3 className="sub-heading mt-3 pt-1">Donec</h3>
                  <h4 className="small-heading">Iaculis vel</h4>
                </div>
              </div>
              <div>
                <div className="team-slider text-center px-2">
                  <div className="team-img p-3">
                    <img className="w-100" src={TeamImg} alt="Img" />
                  </div>
                  <h3 className="sub-heading mt-3 pt-1">Donec</h3>
                  <h4 className="small-heading">Sit congue</h4>
                </div>
              </div>
              <div>
                <div className="team-slider text-center px-2">
                  <div className="team-img p-3">
                    <img className="w-100" src={TeamImg2} alt="Img" />
                  </div>
                  <h3 className="sub-heading mt-3 pt-1">Donec</h3>
                  <h4 className="small-heading">Iaculis vel</h4>
                </div>
              </div>
              <div>
                <div className="team-slider text-center px-2">
                  <div className="team-img p-3">
                    <img className="w-100" src={TeamImg3} alt="Img" />
                  </div>
                  <h3 className="sub-heading mt-3 pt-1">Donec</h3>
                  <h4 className="small-heading">Quam et</h4>
                </div>
              </div>
              <div>
                <div className="team-slider text-center px-2">
                  <div className="team-img p-3">
                    <img className="w-100" src={TeamImg4} alt="Img" />
                  </div>
                  <h3 className="sub-heading mt-3 pt-1">Donec</h3>
                  <h4 className="small-heading">Iaculis vel</h4>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}
