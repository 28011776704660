import React from "react";

const Hero = () => {
  return (
    <>
      <section className="hero-section positon-relative">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div
              className="col-12 col-xxl-8 mt-xl-4 mt-xxl-5 pt-xxl-5 z-index-1"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="hero-content py-5 d-flex flex-column justify-content-center mt-sm-5">
                <h1 className="main-heading text-white my-text-shadow">
                  Welcome to MetaShooters
                </h1>
                <p className="para mt-1 col-xl-11 col-xxl-10 px-xl-3 mx-auto my-3 my-sm-0">
                  The MetaShooters is a story based NFT project. At launch, they
                  are an avatar project and will expand quickly into various
                  MetaVerses like Sandbox and others. The MetaShooters story
                  starts with their adventure “Escape form the MetaVerseBar.” On
                  their journey they will interact with many other NFT projects
                </p>
                <div>
                  <button className="common-btn border-0 mt-sm-4 mt-xl-5 px-4">
                    CONNECT WALLET
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
