export const PlayerIcon = () => (
  <svg
    width="58"
    height="40"
    viewBox="0 0 58 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_205_2054)">
      <path
        d="M56.719 6.23889C56.3863 5.03301 55.7373 3.93365 54.8368 3.05028C53.9362 2.16692 52.8154 1.53036 51.586 1.204C47.0851 0 28.9705 0 28.9705 0C28.9705 0 10.855 0.0364443 6.35412 1.24044C5.12468 1.56682 4.00386 2.20341 3.10328 3.08682C2.20269 3.97023 1.55377 5.06964 1.22112 6.27556C-0.14029 14.1196 -0.668407 26.072 1.25851 33.6022C1.59118 34.8081 2.24013 35.9075 3.14071 36.7908C4.04129 37.6742 5.16209 38.3108 6.39151 38.6371C10.8924 39.8411 29.0074 39.8411 29.0074 39.8411C29.0074 39.8411 47.1222 39.8411 51.6229 38.6371C52.8524 38.3108 53.9732 37.6742 54.8738 36.7909C55.7745 35.9075 56.4234 34.8081 56.7561 33.6022C58.1921 25.7471 58.6346 13.8022 56.719 6.23911V6.23889Z"
        fill="#FF0000"
      />
      <path
        d="M23.2047 28.4578L38.2322 19.9205L23.2047 11.3831V28.4578Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_205_2054">
        <rect width="58" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
/* BackToTop */
export const BackToTopIcon = () => {
  return (
    <>
      <svg
        width="16"
        height="23"
        viewBox="0 0 16 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.49962 11.9993L8 3.49978L13.4992 11.9996"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M2.0001 20.6082L7.77429 12.1143L13.5651 20.6201"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};
