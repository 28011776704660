import React from "react";
import MetaversebarImg1 from ".././assets/img/png/MeetShooters-img-1.png";
import MetaversebarImg2 from ".././assets/img/png/MeetShooters-img-2.png";

const MeetMetaShooter = () => {
  return (
    <>
      <section className="meetshooters pt-5">
        <div className="container mt-xl-5 py-sm-5">
          <div className="meetshooters-content row my-xxl-5 py-sm-5 align-items-center justify-content-center justify-content-lg-between ">
            <div
              className="col-12 col-lg-7 col-xl-6 my-4 my-lg-0 ms-xl-5 px-xl-5 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div className="text-center text-sm-start px-lg-4 px-xl-0">
                <h1 className="heading mb-4 mb-sm-3">
                  <span className="color-pink">Meet </span>
                  the MetaShooters
                </h1>
                <p className="para mb-0">
                  Imagine a mix of Minions and The Smurfs, living in the
                  clubbing, bar, party events and festival scene.
                </p>
                <p className="para my-2 py-1">
                  We are not a Hollywood film studio or ex-Marvel comic artists.
                </p>
                <p className="para mb-0">
                  We are 100% dedicated art and digital space enthusiasts
                  building the most fun, self-deprecating and entertaining NFT
                  Brand, that any marketing manager in the music and event space
                  would love to partner with.
                </p>
                <p className="para mt-2 pt-1">
                  (Please do not underestimate.. some of the first real world
                  brands that dove head first into the NFT space are exactly
                  from this realm, for example... Anheuser–Busch signed with
                  VaynerNFT in July 2021.)
                </p>
                <div className="mt-4 mt-lg-5">
                  <a
                    className="common-btn border-0 text-decoration-none"
                    href="https://opensea.io/"
                    target="_blank"
                  >
                    Get Your MintGlass on opensea.io
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-9 col-sm-7 col-lg-5 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="row">
                <div className="col-6">
                  <img className="w-100" src={MetaversebarImg1} alt="Img" />
                </div>
                <div className="col-6">
                  <img className="w-100" src={MetaversebarImg2} alt="Img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MeetMetaShooter;
