import React from "react";
import roadmap from "../../assets/img/png/roadmap.png";
import roadmapline from "../../assets/img/png/roadmapline.png";
import roadmapsecond from "../../assets/img/png/roadmapsecond.png";
import roadmapsecondline from "../../assets/img/png/roadmapsecondline.png";
import roadmapleft from "../../assets/img/png/roadmapleft.png";
import roadmapdimond from "../../assets/img/png/roadmapdimond.png";
import roadmapshoter from "../../assets/img/png/roadmapshoter.png";
import mobileview from "../../assets/img/png/mobileview.png";
import trophe from "../../assets/img/png/trophe.png";
import backstagepass from "../../assets/img/png/backstagepass.png";
import mobileviewpass from "../../assets/img/png/mobileviewpass.png";

const Roadmap = () => {
  return (
    <>
      <section className="roadmap pt-5 pt-sm-0 pb-lg-5" id="information">
        <div className="container py-lg-5">
          <div className="row mb-sm-5 mb-lg-0 pb-lg-0 pb-5">
            <div className="col-12 pt-5 mt-4">
              <h1 className="heading text-center pt-sm-5 mt-sm-5">
                Why Become a <span className="color-pink">Founder?</span>
              </h1>
            </div>
          </div>
          <div className="row justify-content-lg-between pt-4 mt-xl-5 pt-xl-5">
            <div className="col-4 d-none d-lg-block"></div>
            <div className=" col-5 col-lg-3 my-auto">
              <div className="ps-xl-5 ps-lg-3 ms-sm-5">
                <img
                  className="w-100 ps-md-0 ps-lg-1"
                  src={roadmap}
                  alt="roadmap"
                />
              </div>
              <div className="text-lg-center  ps-sm-2 ps-sm-3 ps-sm-0 ps-md-4 ps-lg-0">
                <img
                  className="roadmap-line ps-3 ps-lg-0 ms-lg-0 me-lg-4 me-xxl-5 pe-xl-0"
                  src={roadmapline}
                  alt="roadmapline"
                />
              </div>
            </div>
            <div
              className="col-7 col-lg-4"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="ps-sm-5 ps-lg-0 pe-lg-5 me-3">
                <h1 className="font-lg text-white  fw-bold">
                  Cashback on launch
                </h1>
                <p className="para pe-xxl-5 me-xl-5 text-capitalize">
                  As soon as the MetaShooters avatar project launches, all
                  founders will receive a cashback up to one ETH from the
                  initial sale.
                </p>
              </div>
            </div>
          </div>
          {/* second-row */}
          <div className="row justify-content-between  mt-25">
            <div
              className="col-7 col-lg-4 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="ps-sm-5 ps-lg-5 text-lg-end pt-sm-4 pt-md-0 pt-lg-0">
                <h1 className="font-lg text-white  fw-bold">Early Access</h1>
                <p className="para ps-xxl-5 ms-xl-5 text-capitalize">
                  Time is crucial, when it comes to minting. As a founder you'll
                  receive exclusive preminting rights to the avatar project.
                </p>
              </div>
            </div>
            <div className="col-5 col-lg-3 pt-sm-4 pt-md-0 order-1 order-lg-2">
              <div className="ms-xl-2 me-lg-4 me-xl-3 ps-lg-3 ps-lg-0 ms-sm-5 ms-lg-0 ms-xxl-0 me-xxl-5 pe-xl-5">
                <img
                  className="w-100 d-lg-none"
                  src={roadmapleft}
                  alt="roadmapsecond"
                />
                <img
                  className="w-100 d-none d-lg-block pe-30"
                  src={roadmapsecond}
                  alt="roadmapsecond"
                />
              </div>
              <div className="text-lg-center ">
                <img
                  className=" roadmap-line-second ps-lg-4   ms-xl-5 ms-xxl-2 ps-xxl-5 ms-35"
                  src={roadmapsecondline}
                  alt="roadmapsecondline"
                />
              </div>
            </div>
            <div className="col-5 d-none d-lg-block order-3"></div>
          </div>

          {/* Third-row */}
          <div className="row justify-content-lg-between mt-25">
            <div className="col-4 d-none d-lg-block"></div>
            <div className=" col-5 col-lg-3 my-auto">
              <div className="ps-xl-5 ps-lg-3 ms-sm-5">
                <img
                  className="w-100 ps-md-0 ps-lg-1"
                  src={roadmapdimond}
                  alt="roadmap"
                />
              </div>
              <div className="text-lg-center  ps-sm-2 ps-sm-3 ps-sm-0 ps-md-4 ps-lg-0">
                <img
                  className="roadmap-line ps-3 ps-lg-0 ms-lg-0 me-lg-4 me-xxl-5 pe-xl-0"
                  src={roadmapline}
                  alt="roadmapline"
                />
              </div>
            </div>
            <div
              className="col-7 col-lg-4"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="ps-sm-5 ps-lg-0 pe-lg-5 me-3">
                <h1 className="font-lg text-white  fw-bold">
                  Exclusive VIP <br /> MetaShooter
                </h1>
                <p className="para pe-xxl-5 me-xl-5 text-capitalize">
                  As founder, you'll be rewarded a very special VIP MetaShooter
                  NFT.
                </p>
              </div>
            </div>
          </div>
          {/* Forth-row */}
          <div className="row justify-content-between  mt-25">
            <div
              className="col-7 col-lg-4 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className=" ps-sm-5 ps-md-0 ps-lg-5 ms-md-5 text-lg-end pt-sm-4 pt-md-0 pt-lg-0">
                <h1 className="font-lg text-white  fw-bold">
                  OG MetaShooters drop{" "}
                </h1>
                <p className="para ps-xxl-5 text-capitalize">
                  There are 30 original MetaShooters on the MetaVerseBars drinks
                  menu. This 30 will be dropped randomly <br /> to MintGlass
                  Token holders.
                </p>
              </div>
            </div>
            <div className="col-5 col-lg-3 pt-sm-4 pt-md-0 order-1 order-lg-2">
              <div className="ms-xl-2 me-lg-4 me-xl-3 ps-lg-3 ps-lg-0 ms-sm-5 ms-lg-0 ms-xxl-0 me-xxl-5 pe-xl-5">
                <img
                  className="w-100 d-lg-none"
                  src={mobileview}
                  alt="roadmapsecond"
                />
                <img
                  className="w-100 d-none d-lg-block pe-30"
                  src={roadmapshoter}
                  alt="roadmapsecond"
                />
              </div>
              <div className="text-lg-center ">
                <img
                  className=" roadmap-line-second ps-lg-4   ms-xl-5 ms-xxl-2 ps-xxl-5 ms-35"
                  src={roadmapsecondline}
                  alt="roadmapsecondline"
                />
              </div>
            </div>
            <div className="col-5 d-none d-lg-block order-3"></div>
          </div>
          {/* Fifth-row */}
          <div className="row justify-content-lg-between mt-25">
            <div className="col-4 d-none d-lg-block"></div>
            <div className=" col-5 col-lg-3 my-sm-auto">
              <div className="ps-xl-5 ps-lg-3 ms-sm-5">
                <img
                  className="w-100 ps-lg-2 ps-xl-0"
                  src={trophe}
                  alt="roadmap"
                />
              </div>
              <div className="text-lg-center  ps-sm-2 ps-sm-3 ps-sm-0 ps-md-4 ps-lg-0 h-100">
                <img
                  className="roadmap-line ps-3 ps-lg-0 ms-lg-0 me-lg-4 me-xxl-5 pe-xl-0 height-100 w-21"
                  src={roadmapline}
                  alt="roadmapline"
                />
              </div>
            </div>
            <div
              className="col-7 col-lg-4"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="ps-sm-5 ps-lg-0 pe-lg-5 me-3">
                <h1 className="font-lg text-white  fw-bold">HappyHour</h1>
                <p className="para pe-xxl-5 me-xl-5 text-capitalize">
                  Only the first 1.000 MetaShooters are minted in the happy
                  hour. And will get 2 for 1. As a founder you are guaranteed to
                  get the happy hour, if you mint additional MetaShooters.
                </p>
              </div>
            </div>
          </div>
          {/* Sixth-row */}
          <div className="row justify-content-between  mt-25">
            <div
              className="col-7 col-lg-4 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="ps-sm-5 ps-lg-5 ps-md-4 ms-md-4 text-lg-end pt-4 pt-lg-0">
                <h1 className="font-lg text-white  fw-bold">Backstage Pass </h1>
                <p className="para ps-xxl-5 text-capitalize">
                  Every founder receives 10 FREE <br /> mints before the general
                  admission starts. (Gas fees only)
                </p>
              </div>
            </div>
            <div className="col-5 col-lg-3 pt-sm-2 pt-md-0 order-1 order-lg-2">
              <div className="ms-xl-2 me-lg-4 me-xl-3 ps-lg-3 ps-lg-0 ms-sm-5 ms-lg-0 ms-xxl-0 me-xxl-5 pe-xl-5">
                <img
                  className="w-100 d-lg-none"
                  src={mobileviewpass}
                  alt="roadmapsecond"
                />
                <img
                  className="w-100 d-none d-lg-block pe-30"
                  src={backstagepass}
                  alt="roadmapsecond"
                />
              </div>
            </div>
            <div className="col-5 d-none d-lg-block order-3"></div>
          </div>

          <div className="row">
            <div
              className="col-12 text-center my-5 my-xl-0 text-md-end me-xxl-5"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="me-xxl-5 pe-lg-5">
                <a
                  href="https://opensea.io/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none"
                >
                  <button className="common-btn border-0 get-btn me-xl-5">
                    Get Your MIntGlass on opensea.io
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Roadmap;
