import React from "react";
import minglass from "../../assets/img/png/minglass.png";

const SaleHeroSection = () => {
  return (
    <>
      <section className="sale-bg">
        <div className="container">
          <div className="row justify-content-center  justify-content-lg-between">
            <div
              className=" col-12 col-lg-6 d-flex flex-column justify-content-center mt-5 pt-4 mt-lg-0 pt-lg-0"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <h1 className="heading founder-heading ">
                <span className="color-pink"> Founders</span> MintGlass
                MetaShooters.
              </h1>
              <p className="para mt-3 me-md-5 pe-md-5">
                Become one of 125 exclusive Patrons to the MetaShooters
                adventurous “escape from the MetaVerseBar” NFT Project.
              </p>
              <div className="mt-sm-4 mt-xl-5">
                <a
                  className="common-btn border-0 text-decoration-none"
                  href="https://opensea.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Your MintGlass on opensea.io
                </a>
              </div>
            </div>

            <div
              className="col-10  col-sm-8 col-lg-5"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="2500"
            >
              <div className="p-lg-5">
                <img className="w-100 p-5  pe-lg-5" src={minglass} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SaleHeroSection;
